body {
  font-family: Arial, sans-serif;
  margin: 0;
  background: #f5f5f5;
}

.app {
  max-width: 500px;
  margin: 20px auto;
  background: #ffffff;
  border: 1px solid #ddd;
  border-radius: 10px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.popup {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.popup-content {
  background: #fff;
  padding: 20px;
  border-radius: 10px;
  text-align: center;
}

.popup-content input {
  width: 100%;
  padding: 8px;
  margin-bottom: 10px;
  font-size: 16px;
}

.phone-display {
  padding: 10px;
  border-bottom: 1px solid #ddd;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.phone-display button {
  padding: 5px 10px;
  font-size: 14px;
  color: #fff;
  background: #007bff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.chat-container {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.chat-history {
  flex-grow: 1;
  padding: 10px;
  overflow-y: auto;
  background: #e5e5e5;
  display: flex;
  flex-direction: column; /* Aligns messages vertically */
  gap: 10px; /* Adds space between each message */
}

.chat-message {
  margin: 5px 0; /* Ensure separation from other messages */
  padding: 10px;
  border-radius: 8px;
  max-width: 80%; /* Ensure messages do not take the full width */
  word-wrap: break-word; /* Break long words for better display */
}

.chat-message.sent {
  align-self: flex-end; /* Align user messages to the right */
  background: #dcf8c6;
  text-align: right;
}

.chat-message.received {
  align-self: flex-start; /* Align Maple messages to the left */
  background: #ffffff;
  text-align: left;
}

.chat-input {
  display: flex;
  padding: 10px;
  border-top: 1px solid #ddd;
  background: #fafafa;
}

.chat-input input {
  flex-grow: 1;
  padding: 8px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.chat-input button {
  margin-left: 10px;
  padding: 8px 16px;
  font-size: 16px;
  color: #fff;
  background: #007bff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.chat-input button:hover {
  background: #0056b3;
}